import React from "react";
import "./ProjectGallery.scss";
import { Carousel, CarouselThumbnail } from "components";
import { ImagePass } from "../../../Image/ImagePass";

export const ProjectGallery = ({ images }) => {
  const carouselOptions = {
    perView: 1,
    bound: true,
    startAt: 0,
    dragThreshold: false,
    swipeThreshold: true,
  };

  const carouselOptionsThumbnails = {
    perView: 4,
    //type: "carousel",
    gap: 16,
    dragThreshold: false,
    swipeThreshold: true,
    rewind: true,
    bound: true,
  };

  return (
    <section className="project-gallery layout">
      <div className="project-gallery-container">
        <CarouselThumbnail
          options={carouselOptions}
          thumbnailOptions={carouselOptionsThumbnails}
          id="project-gallery"
        >
          {Object.keys(images).length > 0 &&
            images?.map((slide, index) => {
              return (
                <div className="image-container" key={index}>
                  <ImagePass src={slide.image} />
                </div>
              );
            })}
        </CarouselThumbnail>
        <Carousel
          options={carouselOptions}
          thumbnailOptions={carouselOptionsThumbnails}
          id="project-gallery-small"
        >
          {Object.keys(images).length > 0 &&
            images?.map((slide, index) => {
              return (
                <div className="image-container" key={index}>
                  <ImagePass src={slide.image} />
                </div>
              );
            })}
        </Carousel>
      </div>
    </section>
  );
};
