import React from "react";
import { graphql } from "gatsby";
import { GatsbyLink, Hero, Button, ImageAndContentBlock } from "components";
import Previewable from "../components/Previewable";
import SEO from "../components/SEO";
import RelatedProjects from "../components/Related/RelatedProjects";
import { RenderShortcode } from "../components/RenderShortcode";
import { ImagePass } from "../components/Image/ImagePass";
import * as ProjectAcfLayout from "../components/Acf/Project";
import "./post.scss";
import { decodeEntities, stripHtmlTags } from "../utils/helpers";

/**
 * Extract our ACF components
 */
const AcfComponent = ({
  location,
  componentName,
  item,
  pageContext,
  index,
  theme,
}) => {
  // If component does not exist in acf folder, print error message
  const ComponentName = ProjectAcfLayout[componentName];
  if (!ComponentName) {
    return (
      <section className="page missing">
        <div className="inner">
          The <strong>"{componentName}"</strong> component is missing.{" "}
          <span>&#128540;</span>
        </div>
      </section>
    );
  } else {
    return (
      <ComponentName
        index={index}
        location={location}
        pageContext={pageContext}
        {...item}
      />
    );
  }
};

const ProjectPost = ({ data, location, pageContext }) => {
  const { wordpressPost, wordpressWpSettings, siteSettings } = data;
  const { acf } = wordpressPost || {};
  const projectPreviewFallback = acf?.layout_projects || acf?.layout;
  const { wordpressUrl } = wordpressWpSettings;
  const {
    options: { projectFooterContent, projectFooterImage },
  } = siteSettings;

  if (!wordpressPost) return null;
  const {
    title,
    content,
    excerpt,
    featured_media,
    yoast,
    categories,
    date,
    author,
  } = wordpressPost;
  return (
    <>
      <SEO
        title={`${yoast.metaTitle || decodeEntities(title)} | ${decodeEntities(
          wordpressWpSettings.title
        )}`}
        desc={excerpt ? stripHtmlTags(excerpt) : null}
        yoast={yoast}
        banner={
          featured_media &&
          featured_media.source_url &&
          featured_media.source_url
        }
        location={location}
      />
      <Hero eyebrow="Projects" heading={title} center isCentered />
      {projectPreviewFallback?.length > 0
        ? projectPreviewFallback?.map((item, index) => {
            if (!item.__typename) return null;
            // Convert "WordPressAcf_My_Component" into "mycomponent"
            const layoutComponentName = item.__typename
              .replace("WordPressAcf_", "")
              .replaceAll("_", "");

            return (
              <AcfComponent
                key={index}
                index={index}
                componentName={layoutComponentName}
                item={item}
                location={location}
                pageContext={pageContext ?? {}}
                theme={acf.pageTheme}
              />
            );
          })
        : null}
      <section className="single-post layout">
        <div className="single-item-container">
          <RenderShortcode content={content} />
          <Button to="/projects/" theme="light">
            Back to projects
          </Button>
        </div>
      </section>
      <div className="single-item-footer">
        <div className="single-item-footer-container">
          <RelatedProjects fullWidth />
          <ImageAndContentBlock
            content={projectFooterContent}
            image={projectFooterImage}
          />
        </div>
      </div>
    </>
  );
};

export default Previewable(ProjectPost, "projects");

export const pageQuery = graphql`
  fragment ProjectFields on wordpress__wp_projects {
    id
    slug
    content
    date(formatString: "MMMM Qo gggg")
    title
  }
  query ProjectPostByID($id: String!) {
    wordpressWpSettings {
      title
      wordpressUrl
      siteUrl
    }
    siteSettings: wordpressAcfOptions {
      options {
        customCss
        projectFooterContent
        projectFooterImage {
          localFile {
            publicURL
            childImageSharp {
              fluid(maxWidth: 600, quality: 70) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      }
    }
    categories: allWordpressWpProjectCategory(filter: { count: { gt: 0 } }) {
      edges {
        node {
          name
          slug
          path
          count
          wordpress_id
        }
      }
    }
    wordpressPost: wordpressWpProjects(id: { eq: $id }) {
      id
      title
      slug
      content
      excerpt
      wordpress_id
      date(formatString: "MMMM Qo gggg")
      featured_media {
        localFile {
          childImageSharp {
            fluid(maxWidth: 670, quality: 70) {
              ...GatsbyImageSharpFluid_noBase64
            }
          }
        }
      }
      acf {
        layout_projects {
          ...Project_ProjectGallery
        }
      }
      yoast {
        metaTitle: title
        metaDescription: metadesc
        opengraph_image {
          source_url
        }
        twitter_image {
          source_url
        }
      }
    }
  }
`;
